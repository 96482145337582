<script>
import {
  authComputed
} from "@/state/helpers";
import { mapState, mapActions, mapMutations } from 'vuex';
import { debounce } from "lodash";

export default {
  props: {
    caseState: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      
      isRecording: false,
    //  titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
    //  showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: 'linear-gradient(to right, #F7931E, #662D91)',
          text: '#ffffff'
        },
        launcher: {
          bg: '#662D91'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#662D91',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#F7931E',
          text: '#ffffff'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: false // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)

    };
  },
  computed: {
    ...authComputed,
    ...mapState('aiAssistent', {
      messageList: 'messageList',
      thinking: 'thinking',
      soundEnabled: 'soundEnabled'
    }),
    messages() {
      return this.messageList.filter(x => x.type === 'text' || x.type === 'system');
    },
    showTypingIndicator() {
      return this.thinking ? 'assistant' : '';
    },
    participants() {
      return [
        {
          id: 'assistant',
          name: 'Assistant',
          //imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
          imageUrl: '/assistant.png'
        }
      ]
   }
  },
  mounted() {
    //this.startChat({caseState: this.caseState, autoUserPrompt: this.autoUserPrompt});
    this.addRecordButton();
  },
  methods: {
    ...mapActions('aiAssistent', {
      startChat: 'startChat',
      sendMsg: 'sendMsg'
    }),
    ...mapMutations('aiAssistent', {
      playSpeech: 'PLAY_SPEECH'
    }),
    debounceStartChat: debounce(function() {
      console.log('');
      this.startChat({caseState: this.caseState});
    }, 500),
    startPlay() {
      this.playSpeech(true);
    },
    stopPlay() {
      this.playSpeech(false);
    },
    startRecording() {
      this.isRecording = true;
      this.transcript = '';

      const lastMsg = this.messages.length === 0 ? null :  this.messages[ this.messages.length-1];
      const lang = lastMsg?.data?.lang || 'en-US';
      console.log('startRecording lang ' + lang);

      console.log('window.SpeechRecognition', window.SpeechRecognition);
      console.log('window.webkitSpeechRecognition', window.webkitSpeechRecognition);
      console.log('window.msSpeechRecognition', window.msSpeechRecognition);

      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || window.msSpeechRecognition;
      const speechRecognizer = new SpeechRecognition();
      speechRecognizer.lang = lang; 
      speechRecognizer.continuous = true; 
      speechRecognizer.start();

      speechRecognizer.onstart = () => {
        console.log('Speech recognition started');
      };

      speechRecognizer.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        this.stopRecording();
      };

      speechRecognizer.onresult = (event) => {
        const resultIndex = event.resultIndex;
        const transcript = event.results[resultIndex][0].transcript;
        if (transcript) {
          const chatInput = this.$refs.chat.$el.querySelector('.sc-user-input--text');
          chatInput.innerText = transcript;
        }
        this.stopRecording();
      };

      this.speechRecognizer = speechRecognizer; // Сохраняем экземпляр speechRecognizer

      this.recordButton.innerHTML = '<i style="font-size:1.5rem; color:red;" class="mdi mdi-stop-circle"/>';
    },

    stopRecording() {
      this.isRecording = false;
      this.recordButton.innerHTML = '<i style="font-size:1.5rem" class="mdi mdi-microphone"/>';

      if (this.speechRecognizer) {
        this.speechRecognizer.stop(); // Останавливаем распознавание речи
        this.speechRecognizer = null; // Уничтожаем экземпляр speechRecognizer
      }
    },

    // sendMessage (text) {
    //   if (text.length > 0) {
    //     this.newMessagesCount = 0
    //     this.sendMsg(text);
    //   }
    // },
    onMessageWasSent (message) {
      this.sendMsg({ text: message.data.text});
    },
    addRecordButton() {
      const recordButton = document.querySelector('.sc-user-input--buttons .record-button');
      if(!recordButton) {
        const buttonsContainer = document.querySelector('.sc-user-input--buttons');
        console.log('buttonsContainer', buttonsContainer);
        const firstButton = buttonsContainer.querySelector('.sc-user-input--button');
        console.log('firstButton', firstButton);
        const buttonHtml = '<div class="sc-user-input--button record-button"><button type="button" data-v-c241d988 class="sc-user-input--button-icon-wrapper"><i style="font-size:1.5rem" class="mdi mdi-microphone"/></button></div>';
        firstButton.remove();
        buttonsContainer.insertAdjacentHTML('afterbegin', buttonHtml);
        this.recordButton = buttonsContainer.querySelector('.record-button button');
        this.recordButton.addEventListener('click', () => {
          if(this.isRecording) {
            this.stopRecording();
          } else {
            this.startRecording();
          }
        });
      }
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
      console.log('openChat');

      this.addRecordButton();
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
      console.log('closeChat');
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
    
    }
  },
  watch: {
    caseState:{
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal !== oldVal) {
          this.debounceStartChat();
        }
      }
    },
    autoUserPrompt:{
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal !== oldVal) {
          this.debounceStartChat();
        }
      }
    }
  }
};
</script>

<template>
   <div>
    <beautiful-chat
      ref="chat"
      :participants="participants"

      :onMessageWasSent="onMessageWasSent"
      :messageList="messages"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      
      :open="openChat"
      :showEmoji="false"
      :showFile="false"
      :showEdition="false"
      :showDeletion="false"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="true"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="true"
      :messageStyling="messageStyling"
      @onType="handleOnType"
    >
      <template v-slot:header>
        <div class="d-flex flex-column">
          <div style="display: block; padding: 0;color: white; font-size: 1.4rem;">C8 AI Assistant</div>
          <div  style="display: block; padding: 0;color: white;">
            <div v-if="!soundEnabled" style="cursor: pointer;" 
              @click="startPlay"
              class="d-flex flex-row align-center border-radius border justify-center px-2"
            >
          
              <span class="mr-2 mdi mdi-play" ></span>
              <span >Play a speech</span>
            </div>
            <div v-if="soundEnabled" style="cursor: pointer;" 
              @click="stopPlay"
              class="d-flex flex-row align-center border-radius border justify-center  px-2">
              <span  class="mr-2 mdi mdi-stop" v-if="soundEnabled"></span>
             
              <span >Stop playing speech</span>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:text-message-body="{ message }">
        {{message.data.text}}
      </template>
      <template v-slot:messageInput>
        <input v-model="chatInput" type="text" />
        <button @click="addTextToInput">Add text</button>
      </template>
    </beautiful-chat>
  </div>

</template>
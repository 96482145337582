<script>
import { monthNames }  from '@/helpers'

export default {
  components: {
   
  },
  props: {
    embedded: {
      type: Boolean,
      required: false
    },
    stat: {
      type: Array
    },
    pdf: {
      type: Boolean,
      required: false
    }
  },
  created(){
 
  },
  data() {
    return {
      monthNames: [...monthNames],
      secondColor: "#6183e8"
    };
  },
  methods:{
   
  },
  computed:{
   
    tableModel() {
      let cells = {};
      let rows = [...new Set((this.stat).map(x => x.year.toString()))];
      let monthlyPerfColumnNames = [...new Set((this.stat).map(x => this.monthNames[x.month-1]))];
      rows.sort((a, b) => a.localeCompare(b));
      rows.reverse();
      //debugger; // eslint-disable-line no-debugger
      rows.forEach(rowName =>{
        const rowCells = this.stat.filter(x => x.year.toString() === rowName);
        let a = {};
        cells[rowName] = a;
        monthlyPerfColumnNames.forEach(columnName =>{
          const c8Value = rowCells.find(x =>  this.monthNames[x.month-1] === columnName) || {};
          a[columnName] = {
            base: c8Value.value,
            additional: c8Value.benchmarkValue
          };
        });
      });
    
      return {
        columns:  monthNames.filter(x => x !== 'YTD'),
        rows: rows,
        cells: cells
      };
    }
  }
};
</script>

<template>
  <div >
    <div class="table-responsive" v-if="embedded">
      <table class="table table-centered table-hover  nowrap">
        <thead>
          <tr>
            <th style="max-width:60px;width:60px; "></th>
            <th v-for="(cName, $index) in tableModel.columns" :key="$index" style="text-align: right;"><b>{{cName}}</b></th>
            <th  style="max-width:100px;width:100px;text-align: right; "><b>YTD</b></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rName, $rIndex) in tableModel.rows" :key=" $rIndex">
            <td style="max-width:60px;width:60px; text-align: center;">{{rName}}</td>
            <td v-for="(cName, $index) in tableModel.columns" :key="$index" style="text-align: right;">
              
              <div>
                {{(tableModel.cells[rName][cName] || {}).base}}
                <div :style="{'color': secondColor}" v-if="(tableModel.cells[rName][cName] || {}).additional">
                    {{(tableModel.cells[rName][cName] || {}).additional}}
                </div>
              </div>
            </td>
            <td style="max-width:100px;width:100px; text-align: right;">
              {{(tableModel.cells[rName]['YTD'] || {}).base}}
              <div :style="{'color': secondColor}" v-if="(tableModel.cells[rName]['YTD'] || {}).additional">
                  {{(tableModel.cells[rName]['YTD'] || {}).additional}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card cardc8" v-if="!embedded">
      <div class="'card-content'">
        <div class="card-body">

          <!-- Header -->
          <div class="row">
            <div class="col">
              <h5 class="card-title">Monthly performance</h5>
            </div>
          </div>

          <div class="table-responsive" v-if="!pdf">
            <table class="table table-centered table-hover nowrap">
              <thead>
                <tr>
                  <th style="max-width:60px;width:60px; "></th>
                  <th v-for="(cName, $index) in tableModel.columns" :key="$index" style="text-align: right;"><b>{{cName}}</b></th>
                  <th  style="max-width:100px;width:100px;text-align: right; "><b>YTD</b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rName, $rIndex) in tableModel.rows" :key=" $rIndex">
                  <td style="max-width:60px;width:60px; text-align: center;">{{rName}}</td>
                  <td v-for="(cName, $index) in tableModel.columns" :key="$index" style="text-align: right;">
                    
                    <div>
                      {{(tableModel.cells[rName][cName] || {}).base}}
                      <div :style="{'color': secondColor}" v-if="(tableModel.cells[rName][cName] || {}).additional">
                          {{(tableModel.cells[rName][cName] || {}).additional}}
                      </div>
                    </div>
                  </td>
                  <td style="max-width:100px;width:100px; text-align: right;">
                    {{(tableModel.cells[rName]['YTD'] || {}).base}}
                    <div :style="{'color': secondColor}" v-if="(tableModel.cells[rName]['YTD'] || {}).additional">
                        {{(tableModel.cells[rName]['YTD'] || {}).additional}}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="table" v-if="pdf">
            <table 
              class="table table-centered table-stripped table-sm datatable table-hover dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 700px;font-size:10px;"
            >
              <thead>
                <tr>
                  <th style="max-width:60px;width:60px; "></th>
                  <th v-for="(cName, $index) in tableModel.columns" :key="$index" style="text-align: right;"><b>{{cName}}</b></th>
                  <th style="text-align: right;"><b>YTD</b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rName, $rIndex) in tableModel.rows" :key=" $rIndex">
                  <td style="max-width:60px;width:60px; text-align: center;">{{rName}}</td>
                  <td v-for="(cName, $index) in tableModel.columns" :key="$index" style="text-align: right;">
                    
                    <div>
                      {{(tableModel.cells[rName][cName] || {}).base}}
                      <div :style="{'color': secondColor}" v-if="(tableModel.cells[rName][cName] || {}).additional">
                          {{(tableModel.cells[rName][cName] || {}).additional}}
                      </div>
                    </div>
                  </td>
                  <td style="max-width:60px;width:60px; text-align: center;">
                    {{(tableModel.cells[rName]['YTD'] || {}).base}}
                    <div :style="{'color': secondColor}" v-if="(tableModel.cells[rName]['YTD'] || {}).additional">
                        {{(tableModel.cells[rName]['YTD'] || {}).additional}}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>